@import '~antd/dist/antd.less';

@font-face {
  font-family: 'Bio Sans';
  src:
          local('Bio Sans Regular'),
          local('BioSans-Regular'),
          url(./assets/fonts/BioSans-Regular.woff) format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Bio Sans';
  src:
          local('Bio Sans Light'),
          local('BioSans-Light'),
          url(./assets/fonts/BioSans-Light.woff) format('woff');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Bio Sans';
  src:
          local('Bio Sans Semibold'),
          local('BioSans-Semibold'),
          url(./assets/fonts/BioSans-SemiBold.woff) format('woff');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Bio Sans';
  src:
          local('Bio Sans Bold'),
          local('BioSans-Bold'),
          url(./assets/fonts/BioSans-Bold.woff) format('woff');
  font-weight: 700;
  font-display: swap;
}

body,
html,
div,
blockquote,
img,
label,
button,
p,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
ul,
ol,
li,
dl,
dt,
dd,
form,
a,
fieldset,
input,
th,
td {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
}

button:not(:disabled),
[role='button']:not(:disabled) {
  cursor: pointer;
}

body {
  font-family: 'Bio Sans', sans-serif;
}

@primary-color: #07093D;@heading-color: #07093D;